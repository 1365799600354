import { Api, BaseService } from "@alpha/com.bizentro.daaf.front.framework";

class ElementService extends BaseService {
  static api = new BaseService("/element");
  static apiPrefix = "/element";

  static dummyElement = (data) => {
    return this.api.sendPost("/dummyElement", data);
  };

  static getElement = (data) => {
    return this.api.sendPost("/getElement", data);
  };

  static getElementList = (data) => {
    return this.api.sendPost("/getElementList", data);
  };

  static saveElement = (data) => {
    return this.api.sendPost("/saveElement", data);
  };

  static deleteElement = (data) => {
    return this.api.sendPost("/deleteElement", data);
  };

  static getPopupProgram = (data, successCallback, errorCallback) => {
    const urlPath = `${process.env.REACT_APP_BUILDER_API_URL}/program/`;
    return Api.post(
      urlPath + "/programList",
      data,
      successCallback,
      errorCallback
    );
  };

  /**
   * Element Nm List로 사용하는 ElementList 리턴
   * @param {*} data
   * @returns
   */
  static getElementListByElementNm = (data) => {
    return this.api.sendPost("/getElementListByElementNm", data);
  };

  /**
   * Domain이 있는 Element 목록만 리턴
   * @param {*} data
   * @returns
   */
  static getElementContainsDomainList = (data) => {
    return this.api.sendPost("/getElementContainsDomainList", data);
  };

  /**
   * Domain을 포함한 Element 목록만 리턴
   * @param {*} data
   * @returns
   */
  static getElementListContainsDomainObj = (data) => {
    return this.api.sendPost("/getElementListContainsDomainObj", data);
  };

  /**
   * Domain을 사용하는 Element 목록 리턴
   * @param {*} data
   * @returns
   */
  static getElementListByDomainId = (data) => {
    return this.api.sendPost("/getElementListByDomainId", data);
  };
}

export default ElementService;
